const xiuxianhuodong = [
  {
    title: '2025年',
    children: [
      {
        img: require('../assets/xiuxianyule/2025/0122/1.jpg'),
        title: '年终组会，聚餐',
        time: '2025年01月22日',
        children: [
          require('../assets/xiuxianyule/2025/0122/1.jpg'),
          require('../assets/xiuxianyule/2025/0122/2.jpg'),
          require('../assets/xiuxianyule/2025/0122/3.jpg'),
        ]
      },
    ]
  },
  {
    title: '2024年',
    children: [
      {
        img: require('../assets/xiuxianyule/2024/graduation/tiankang2.jpeg'),
        title: '毕业',
        time: '2024年5月23日',
        children: [
          require('../assets/xiuxianyule/2024/graduation/tiankang2.jpeg'),
          require('../assets/xiuxianyule/2024/graduation/liyuzhe2.jpeg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2024/annual_retreat/2.jpeg'),
        title: '年会',
        time: '2024年4月26日',
        children: [
          require('../assets/xiuxianyule/2024/annual_retreat/2.jpeg'),
          require('../assets/xiuxianyule/2024/annual_retreat/3.jpg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2024/annual_retreat/1.jpg'),
        title: '年会，轰趴',
        time: '2024年1月28日',
        children: [
          require('../assets/xiuxianyule/2024/annual_retreat/1.jpg')


        ]
      },
    ]
  },
  {
    title: '2023年',
    children: [
      {
        img: require('../assets/xiuxianyule/2023/graduation/yangrui.jpg'),
        title: '毕业',
        time: '2023年12月21日',
        children: [
          require('../assets/xiuxianyule/2023/graduation/yangrui.jpg')


        ]
      }, 
      {
        img: require('../assets/xiuxianyule/2023/nianhui/1.png'),
        title: '年会, 京东大峡谷',
        time: '2023年3月10-11日',
        children: [
          require('../assets/xiuxianyule/2023/nianhui/2.png'),
          require('../assets/xiuxianyule/2023/nianhui/3.png'),
          require('../assets/xiuxianyule/2023/nianhui/4.png'),
          require('../assets/xiuxianyule/2023/nianhui/5.png'),
          require('../assets/xiuxianyule/2023/nianhui/6.png'),
          require('../assets/xiuxianyule/2023/nianhui/7.png'),
          require('../assets/xiuxianyule/2023/nianhui/8.png'),
          require('../assets/xiuxianyule/2023/nianhui/9.png'),
          require('../assets/xiuxianyule/2023/nianhui/10.png'),
          require('../assets/xiuxianyule/2023/nianhui/11.png'),
          require('../assets/xiuxianyule/2023/nianhui/12.png')


        ]
      },     
      
    ]
  },
  {
    title: '2022年',
    children: [

      {
        img: require('../assets/xiuxianyule/2022/graduate/2-2.jpg'),
        title: '欢送会',
        time: '2022年9月28日',
        children: [
          require('../assets/xiuxianyule/2022/graduate/2-1.jpg'),
          require('../assets/xiuxianyule/2022/graduate/2-2.jpg')
        ]
      },

      {
        img: require('../assets/xiuxianyule/2022/graduate/1.jpg'),
        title: '毕业',
        time: '2022年9月12日',
        children: [
          require('../assets/xiuxianyule/2022/graduate/1.jpg'),
          require('../assets/xiuxianyule/2022/graduate/2.jpg'),
          require('../assets/xiuxianyule/2022/graduate/3.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2022/birthday/1.jpg'),
        title: '教师节加生日会',
        time: '2022年9月7日',
        children: [
          require('../assets/xiuxianyule/2022/birthday/1.jpg'),
          require('../assets/xiuxianyule/2022/birthday/2.jpg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2022/xiuxian/1.jpg'),
        title: '七夕活动',
        time: '2022年8月4日',
        children: [
          require('../assets/xiuxianyule/2022/xiuxian/1.jpg'),
          require('../assets/xiuxianyule/2022/xiuxian/2.jpg')
        ]
      }
    ]
  },
  {
    title: '2021年',
    children: [

      {
        img: require('../assets/xiuxianyule/2021/graduate/2_1.jpg'),
        title: '毕业',
        time: '2021年9月12日',
        children: [
          require('../assets/xiuxianyule/2021/graduate/2_1.jpg'),
          require('../assets/xiuxianyule/2021/graduate/2_2.jpg'),
          require('../assets/xiuxianyule/2021/graduate/2_3.jpg')
        ]
      },

      {
        img: require('../assets/xiuxianyule/2021/barbecue/1.jpg'),
        title: '木屋烧烤',
        time: '2021年6月22日',
        children: [
          require('../assets/xiuxianyule/2021/barbecue/1.jpg'),
          require('../assets/xiuxianyule/2021/barbecue/2.jpg'),
          require('../assets/xiuxianyule/2021/barbecue/3.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2021/graduate/1.jpg'),
        title: '毕业',
        time: '2021年5月23日',
        children: [
          require('../assets/xiuxianyule/2021/graduate/1.jpg'),
          require('../assets/xiuxianyule/2021/graduate/2.jpg'),
          require('../assets/xiuxianyule/2021/graduate/3.jpg'),
          require('../assets/xiuxianyule/2021/graduate/4.jpg'),
          require('../assets/xiuxianyule/2021/graduate/5.jpg')
        ]
      }
    ]
  },


  {
    title: '2020年',
    children: [
      {
        img: require('../assets/xiuxianyule/2020/graduate/4.jpg'),
        title: '毕业',
        time: '2020年9月14日',
        children: [
          require('../assets/xiuxianyule/2020/graduate/1.jpg'),
          require('../assets/xiuxianyule/2020/graduate/2.jpg'),
          require('../assets/xiuxianyule/2020/graduate/3.jpg'),
          require('../assets/xiuxianyule/2020/graduate/4.jpg'),
          require('../assets/xiuxianyule/2020/graduate/5.jpg'),
          require('../assets/xiuxianyule/2020/graduate/6.jpg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2020/birthday/cover.jpg'),
        title: '生日会',
        time: '2020年9月14日',
        children: [
          require('../assets/xiuxianyule/2020/birthday/1.jpg'),
          require('../assets/xiuxianyule/2020/birthday/2.jpg'),
          require('../assets/xiuxianyule/2020/birthday/3.jpg')
        ]
      }
    ]
  },
  {
    title: '2019年',
    children: [
      {
        img: require('../assets/xiuxianyule/2019/2019jucan-1.jpg'),
        title: '年终聚餐',
        time: '2019年12月29日',
        children: [
          require('../assets/xiuxianyule/2019/2019jucan-1.jpg'),
          require('../assets/xiuxianyule/2019/2019jucan-2.jpg'),
          require('../assets/xiuxianyule/2019/2019jucan-3.jpg'),
          require('../assets/xiuxianyule/2019/2019jucan-4.jpg'),
          require('../assets/xiuxianyule/2019/2019jucan-5.jpg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2019/2019qinglongxia-2.jpg'),
        title: '青龙峡风景区',
        time: '2019年9月21日',
        children: [
          require('../assets/xiuxianyule/2019/2019qinglongxia-1.jpg'),
          require('../assets/xiuxianyule/2019/2019qinglongxia-2.jpg'),
          require('../assets/xiuxianyule/2019/2019qinglongxia-3.jpg'),
          require('../assets/xiuxianyule/2019/2019qinglongxia-4.jpg'),
          require('../assets/xiuxianyule/2019/2019qinglongxia-5.jpg'),
          require('../assets/xiuxianyule/2019/2019qinglongxia-6.jpg'),
          require('../assets/xiuxianyule/2019/2019qinglongxia-7.jpg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2019/2019homepark-1.jpeg'),
        title: '宝华山庄真人CS,轰趴',
        time: '2019年6月22日',
        children: [
          require('../assets/xiuxianyule/2019/2019homepark-1.jpeg'),
          require('../assets/xiuxianyule/2019/2019homepark-2.jpeg'),
          require('../assets/xiuxianyule/2019/2019homepark-3.jpeg'),
          require('../assets/xiuxianyule/2019/2019homepark-4.jpeg'),
          require('../assets/xiuxianyule/2019/2019homepark-5.jpeg'),
          require('../assets/xiuxianyule/2019/2019homepark-6.jpeg')
        ]
      }
    ]
  },
  {
    title: '2018年',
    children: [
      {
        img: require('../assets/xiuxianyule/2018/changpinghongpa.png'),
        title: '昌平轰趴',
        time: '2018年12月16日',
        children: [
          require('../assets/xiuxianyule/2018/changping/2018-12-16-1.jpeg'),
          require('../assets/xiuxianyule/2018/changping/2018-12-16-2.jpeg'),
          require('../assets/xiuxianyule/2018/changping/2018-12-16-3.jpeg'),
          require('../assets/xiuxianyule/2018/changping/2018-12-16-4.jpeg'),
          require('../assets/xiuxianyule/2018/changping/2018-12-16-5.jpeg'),
          require('../assets/xiuxianyule/2018/changping/2018-12-16-6.jpeg')
        ]

      },
      {
        img: require('../assets/xiuxianyule/2018/gubeishuizhen.png'),
        title: '古北水镇',
        time: '2018年9月1日',
        children: [
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-1.jpeg'),
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-2.jpeg'),
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-3.jpeg'),
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-4.jpeg'),
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-5.jpeg'),
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-6.jpeg'),
          require('../assets/xiuxianyule/2018/gubeishuizhen/2018-09-7.jpeg')
        ]
      },
      // {
      //   img: require('../assets/xiuxianyule/2018/shengri.png'),
      //   title: '生日',
      //   time: '2018年12月16日',
      //   children: [
      //     require('../assets/xiuxianyule/2018/shengri/2017-01-1024x768.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/2017-02-1024x768.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201510-1.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201601-1.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201601-2.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201602-1.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201602-2.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201603-1-1024x1024.png'),
      //     require('../assets/xiuxianyule/2018/shengri/201603-2-576x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201604-1-1024x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201604-3-1024x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201604-4-1024x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201604-5-683x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201606-2-768x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201609-1.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201609-1 (1).jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201609-2.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201610.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201611-1-1024x768.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201611-2-768x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201612-1.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201612-2-768x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201612-3-768x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201612-4-1024x768.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/2017-01-1024x768.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/2017-02-1024x768.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201703-birthday-1024x1024.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201705-1.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201705-2-930x1024.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201705-3-1024x1024.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201709-1-768x1024.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201709-2-1024x768.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/Birthday_201702_4-1024x1024.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201710-3.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201710-2.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201711-1.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201711-2.jpg'),
      //     require('../assets/xiuxianyule/2018/shengri/201712-1.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201712-2.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201800-1.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201800-2.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201805-1.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201805-3.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201805-4.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201805-5.jpeg'),
      //     require('../assets/xiuxianyule/2018/shengri/201805-6.jpeg'),
      //   ]
      // },
      {
        img: require('../assets/xiuxianyule/2018/jundushan.png'),
        title: '军都山滑雪',
        time: '2018年1月22日',
        children: [
          require('../assets/xiuxianyule/2018/jundushan/20180120_1.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_2.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_3.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_4.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_5.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_6.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_7.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_8.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_9.jpeg'),
          require('../assets/xiuxianyule/2018/jundushan/20180120_10.jpeg')
        ]
      }
    ]
  },
  {
    title: '2017年',
    children: [
      {
        img: require('../assets/xiuxianyule/2017/liangzhounian.png'),
        title: '实验室两周年纪念！',
        time: '2017年6月30日',
        children: [
          require('../assets/xiuxianyule/2017/liangzhounian/flowers-768x1024.jpeg'),
          require('../assets/xiuxianyule/2017/liangzhounian/graduation-1024x768.jpeg'),
          require('../assets/xiuxianyule/2017/liangzhounian/lab_birthday-768x1024.jpeg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2017/qixing.png'),
        title: '骑行！',
        time: '2017年3月28日',
        children: [
          require('../assets/xiuxianyule/2017/qixing/20170319-1.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-2.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-3.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-4.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-5.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-6.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-7.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-8.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-9.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-10-768x1024.jpeg'),
          require('../assets/xiuxianyule/2017/qixing/20170319-11-768x1024.jpeg')
        ]
      }
    ]
  },
  {
    title: '2016年',
    children: [
      {
        img: require('../assets/xiuxianyule/2016/shiduqiuyou.png'),
        title: '十渡秋游',
        time: '2016年10月21日',
        children: [
          require('../assets/xiuxianyule/2016/qiuyou/1.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/2.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/3.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/4.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/5.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/6.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/7.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/8.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/9.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/10.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/11.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/12.jpg'),
          require('../assets/xiuxianyule/2016/qiuyou/13.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/kanghong.png'),
        title: '抗"洪"抗"险"',
        time: '2016年7月21日',
        children: [
          require('../assets/xiuxianyule/2016/kanghong/floodfighting-20160720-01-768x1024.jpg'),
          require('../assets/xiuxianyule/2016/kanghong/floodfighting-20160720-02-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/kanghong/floodfighting-20160720-03-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/kanghong/floodfighting-20160720-04-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/kanghong/floodfighting-20160720-05-1024x768.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/jinian.png'),
        title: '实验室一周年纪念',
        time: '2016年6月14日',
        children: [
          require('../assets/xiuxianyule/2016/jinian/flowers-768x1024.jpeg'),
          require('../assets/xiuxianyule/2016/jinian/graduation-1024x768.jpeg'),
          require('../assets/xiuxianyule/2016/jinian/lab_birthday-768x1024.jpeg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/shiyanshi.png'),
        title: '瞧，忙碌的实验室！',
        time: '2016年6月14日',
        children: [
          require('../assets/xiuxianyule/2016/shiyanshi/working-1-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/shiyanshi/working-2-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/shiyanshi/working-3-1024x768.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/xiaoqing.png'),
        title: '清华校庆-校园半日游',
        time: '2016年4月14日',
        children: [
          require('../assets/xiuxianyule/2016/xiaoyuan/tsinghua_anniversary-20160423-1-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/xiaoyuan/tsinghua_anniversary-20160423-2-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/xiaoyuan/tsinghua_anniversary-20160423-3-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/xiaoyuan/tsinghua_anniversary-20160423-4-768x1024.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/huairou.png'),
        title: '怀柔春游',
        time: '2016年4月14日',
        children: [
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-2-768x1024.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-4-768x1024.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-5-1024x576.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-6.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-7.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-8.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-9-768x1024.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-10-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-10-1024x768.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-11-1024x576.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-12.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-13.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-14.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-15.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-14.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-17.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-18.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-19.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-21-1024x576.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-20.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-21-1024x576.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-22.jpg'),
          require('../assets/xiuxianyule/2016/huairouchunyou/spring_trip-20160416-23.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/jialu.png'),
        title: '家璐率队勇夺马杯足球冠军',
        time: '2016年4月14日',
        children: [
          require('../assets/xiuxianyule/2016/zuqiu/1.jpg'),
          require('../assets/xiuxianyule/2016/zuqiu/2.jpg'),
          require('../assets/xiuxianyule/2016/zuqiu/3.jpg'),
          require('../assets/xiuxianyule/2016/zuqiu/4.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2016/lianyi.png'),
        title: 'E区实验室联谊',
        time: '2016年2月3日',
        children: [
          require('../assets/xiuxianyule/2016/lianyi/1.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/2.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/4.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/5.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/6.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/8.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/9.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/10.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/11.jpg'),
          require('../assets/xiuxianyule/2016/lianyi/12.jpg'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2015/cs.png'),
        title: '真人CS',
        time: '2016年2月3日',
        children: [
          require('../assets/xiuxianyule/2015/cs/1.jpg'),
          require('../assets/xiuxianyule/2015/cs/2.jpg'),
          require('../assets/xiuxianyule/2015/cs/3.jpg'),
          require('../assets/xiuxianyule/2015/cs/4.jpg'),
          require('../assets/xiuxianyule/2015/cs/5.jpg'),
          require('../assets/xiuxianyule/2015/cs/6.jpg')
        ]
      }
    ]
  },
  {
    title: '2015年',
    children: [
      {
        img: require('../assets/xiuxianyule/2015/jiaoshijie.png'),
        title: '教师节快乐',
        time: '2015年9月11日',
        children: [
          require('../assets/xiuxianyule/2015/jiaoshijie/jiaoshijie.png'),
        ]
      },
      {
        img: require('../assets/xiuxianyule/2015/mishitaotuo.png'),
        title: '密室逃脱',
        time: '2015年7月28日',
        children: [
          require('../assets/xiuxianyule/2015/mishitaotuo/1.jpg'),
          require('../assets/xiuxianyule/2015/mishitaotuo/2.jpg'),
          require('../assets/xiuxianyule/2015/mishitaotuo/3.jpg'),
          require('../assets/xiuxianyule/2015/mishitaotuo/4.jpg'),
          require('../assets/xiuxianyule/2015/mishitaotuo/5.jpg'),
          require('../assets/xiuxianyule/2015/mishitaotuo/6.jpg')
        ]
      },
      {
        img: require('../assets/xiuxianyule/2015/xiaotangshan.png'),
        title: '小汤山春游',
        time: '2015年4月23日',
        children: [
          require('../assets/xiuxianyule/2015/xiaotangshan/1.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/2.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/3.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/4.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/5.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/6.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/7.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/8.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/9.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/10.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/11.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/12.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/13.jpg'),
          require('../assets/xiuxianyule/2015/xiaotangshan/14.jpg')
        ]
      }
    ]
  },
  {
    title: '生日会',
    children: [
      {
        img: require('../assets/xiuxianyule/2018/shengri.png'),
        title: '生日会',
        time: '2018年12月16日',
        children: [
          require('../assets/xiuxianyule/2018/shengri/2017-01-1024x768.jpg'),
          require('../assets/xiuxianyule/2018/shengri/2017-02-1024x768.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201510-1.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201601-1.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201601-2.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201602-1.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201602-2.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201603-1-1024x1024.png'),
          require('../assets/xiuxianyule/2018/shengri/201603-2-576x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201604-1-1024x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201604-3-1024x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201604-4-1024x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201604-5-683x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201606-2-768x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201609-1.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201609-1 (1).jpg'),
          require('../assets/xiuxianyule/2018/shengri/201609-2.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201610.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201611-1-1024x768.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201611-2-768x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201612-1.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201612-2-768x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201612-3-768x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201612-4-1024x768.jpg'),
          require('../assets/xiuxianyule/2018/shengri/2017-01-1024x768.jpg'),
          require('../assets/xiuxianyule/2018/shengri/2017-02-1024x768.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201703-birthday-1024x1024.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201705-1.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201705-2-930x1024.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201705-3-1024x1024.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201709-1-768x1024.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201709-2-1024x768.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/Birthday_201702_4-1024x1024.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201710-3.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201710-2.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201711-1.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201711-2.jpg'),
          require('../assets/xiuxianyule/2018/shengri/201712-1.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201712-2.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201800-1.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201800-2.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201805-1.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201805-3.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201805-4.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201805-5.jpeg'),
          require('../assets/xiuxianyule/2018/shengri/201805-6.jpeg'),
        ]
      }
    ]
  }
]

export default xiuxianhuodong
